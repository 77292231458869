<template>
  <v-card>
    <v-card-subtitle :style="isError ? { color: 'red' } : {}">Налаштування діапазонів нарахування бонусів</v-card-subtitle>
    <div v-if="isError" class="v-application v-messages v-messages__message error--text px-4 py-4">Це поле обов"язкове</div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="blue"
          class="ml-2"
          v-bind="attrs"
          v-on="on"
          :disabled="!!conditions.length && conditions[conditions.length - 1].condition_amount_to === null"
          @click="addRange"
        >
          mdi-plus-circle-outline
        </v-icon>
      </template>
      Додати діапазон
    </v-tooltip>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center" colspan="2">Сума покупок, грн.</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
          <tr class="v-alert__border--bottom">
            <th class="text-center">Від</th>
            <th class="text-center">До</th>
            <th class="text-center">Тип бонусу</th>
            <th class="text-center">Розмір</th>
            <th class="text-center">Дії</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(condition, index) in conditions" :key="index">
            <td class="text-center">{{ condition.condition_amount_from }}</td>
            <td class="text-center">{{ condition.condition_amount_to }}</td>
            <td class="text-center">{{ getValueType(condition.type) }}</td>
            <td class="text-center">{{ condition.amount }}</td>
            <td class="text-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="editRange(condition, index)">
                    <v-icon small v-bind="attrs" v-on="on">mdi-pencil-outline</v-icon>
                  </v-btn>
                </template>
                Редагувати
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon :disabled="index < conditions.length - 1" @click="deleteRange(index)">
                    <v-icon small v-bind="attrs" v-on="on">mdi-delete-outline</v-icon>
                  </v-btn>
                </template>
                Видалити
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog v-model="dialogActive" max-width="600" @click:outside="$emit('no')">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ isEditing ? 'Відредагувати' : 'Додати' }} діапазон</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model.number="condition.condition_amount_from"
                  type="number"
                  label="від"
                  :disabled="!!conditions.length"
                  :error-messages="inputErrors('condition_amount_from')"
                  @blur="$v.condition.condition_amount_from.$touch()"
                  @input="$v.condition.condition_amount_from.$touch()"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model.number="condition.condition_amount_to"
                  :disabled="isEditing && editedIndex < conditions.length - 1"
                  type="number"
                  label="до"
                  :error-messages="inputErrors('condition_amount_to')"
                  @blur="$v.condition.condition_amount_to.$touch()"
                  @input="$v.condition.condition_amount_to.$touch()"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  v-model="condition.type"
                  :items="valueTypes"
                  item-text="value"
                  item-value="id"
                  label="Тип бонуса"
                  :error-messages="inputErrors('type')"
                  @blur="$v.condition.type.$touch()"
                  @input="$v.condition.type.$touch()"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model.number="condition.amount"
                  type="number"
                  label="Розмір"
                  :error-messages="inputErrors('amount')"
                  @blur="$v.condition.amount.$touch()"
                  @input="$v.condition.amount.$touch()"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="yesClicked">Так</v-btn>
          <v-btn text @click="close">Нi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <DeleteDialog :is-opened="dialogDelete" message="Діапазон буде видалено" @yes="deleteConfirm" @no="closeDelete" />
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import DeleteDialog from '@/components/dialogs/DeleteDialog'
import { validationMixin } from 'vuelidate'
import { minValue, required } from 'vuelidate/lib/validators'

export default {
  name: 'ConditionsArray',
  components: { DeleteDialog },

  emits: ['add', 'edit', 'delete'],

  mixins: [validationMixin],

  props: {
    values: {
      type: Array,
      default: () => [],
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dialogActive: false,
      dialogDelete: false,
      isEditing: false,
      conditions: [],
      condition: {},
      firstCondition: {
        condition_amount_from: 0.01,
        condition_amount_to: null,
        type: 2,
        amount: 1,
      },
      editedIndex: -1,
    }
  },

  validations() {
    return {
      condition: {
        condition_amount_from: { required, minValue: minValue(this.firstCondition.condition_amount_from) },
        condition_amount_to: { minValue: minValue(this.fromValue) },
        type: { required },
        amount: { required },
      },
    }
  },

  created() {
    this.initialize()
  },

  watch: {
    values: {
      handler(val) {
        this.conditions = [...val]
      },
      deep: true,
    },
  },

  computed: {
    ...mapState('promotions', ['valueTypes']),

    fromValue() {
      return this.condition.condition_amount_from
    },
  },

  methods: {
    initialize() {
      this.conditions = [...this.values]
    },

    getValueType(type) {
      const val = this.valueTypes.find((item) => item.id === type)
      return val?.value || null
    },

    inputErrors(fieldName) {
      const errors = []
      if (!this.$v.condition[fieldName].$dirty) return errors
      this.$v.condition[fieldName].required === false && errors.push('Це поле обов"язкове')
      this.$v.condition[fieldName].minValue === false && errors.push('Неприпустиме значення')
      return errors
    },

    addRange() {
      if (this.conditions.length) {
        const lastCondition = this.conditions[this.conditions.length - 1]
        const newCondition = {
          ...lastCondition,
          condition_amount_from: lastCondition.condition_amount_to + 0.01,
          condition_amount_to: null,
        }
        this.condition = { ...newCondition }
      } else {
        this.condition = { ...this.firstCondition }
      }
      this.isEditing = false
      this.dialogActive = true
    },

    editRange(condition, index) {
      this.editedIndex = index
      this.isEditing = true
      this.condition = { ...condition }
      this.dialogActive = true
    },

    deleteRange(index) {
      this.editedIndex = index
      this.dialogDelete = true
    },

    closeDelete() {
      this.editedIndex = -1
      this.dialogDelete = false
    },

    deleteConfirm() {
      this.$emit('delete', this.editedIndex)
      this.closeDelete()
    },

    close() {
      this.$v.condition.$reset()
      this.dialogActive = false
      this.isEditing = false
      this.editedIndex = -1
    },

    yesClicked() {
      this.$v.condition.$touch()
      if (this.$v.$anyError) return
      this.isEditing
        ? this.$emit('edit', { payload: this.condition, index: this.editedIndex })
        : this.$emit('add', this.condition)
      this.close()
    },
  },
}
</script>
