<template>
  <v-dialog v-model="active" max-width="1000" @click:outside="$emit('no')">
    <template v-for="slot of Object.keys($slots)" v-slot:[slot]>
      <slot :name="slot" />
    </template>

    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" small v-bind="attrs" v-on="on">Додати акцію</v-btn>
    </template>

    <v-card>
      <v-card-title>
        <span class="text-h5">{{ isEditing ? 'Редагування' : 'Створення' }} акції</span>
      </v-card-title>

      <v-stepper v-model="currentStep" flat>
        <v-stepper-header>
          <v-stepper-step :complete="currentStep > 1" step="1">Оберіть тип акції</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="currentStep > 2" step="2">Вкажіть дані акції</v-stepper-step>
        </v-stepper-header>
        <v-stepper-content step="1">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="model.is_available_once"
                  :items="promotionTypes"
                  disabled
                  item-text="value"
                  item-value="id"
                  label="Вид акції"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  v-model="model.type"
                  :items="companyPromotionsDictionary"
                  item-text="name"
                  item-value="value"
                  label="Тип акції"
                  @change="
                    () => {
                      model.is_available_once = model.type === 6
                    }
                  "
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!model.type" color="primary" text @click="stepForward">
              Далі
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-btn text @click="close">Скасувати</v-btn>
          </v-card-actions>
        </v-stepper-content>
        <v-stepper-content step="2">
          <v-card-text>
            <v-row>
              <v-col v-if="currentTypeParams.start_at && !currentTypeParams.start_at.hide" cols="12" sm="6" md="4">
                <DatePicker
                  :disabled="!currentTypeParams.start_at.editable"
                  :value="model.start_at"
                  :error-messages="inputErrors('start_at')"
                  label="Термін дії з"
                  @change="(date) => (model.start_at = date)"
                  @blur="$v.model.start_at.$touch()"
                  @input="$v.model.start_at.$touch()"
                />
              </v-col>
              <v-col v-if="currentTypeParams.expired_at && !currentTypeParams.expired_at.hide" cols="12" sm="6" md="4">
                <DatePicker
                  :disabled="!currentTypeParams.expired_at.editable"
                  :value="model.expired_at"
                  :error-messages="inputErrors('expired_at')"
                  label="Термін дії до"
                  @change="(date) => (model.expired_at = date)"
                  @blur="$v.model.expired_at.$touch()"
                  @input="$v.model.expired_at.$touch()"
                />
              </v-col>
              <v-col v-if="currentTypeParams.title && !currentTypeParams.title.hide" cols="12" sm="6" md="4">
                <v-text-field
                  v-model="model.title"
                  :disabled="!currentTypeParams.title.editable"
                  :error-messages="inputErrors('title')"
                  label="Назва акції"
                  @blur="$v.model.title.$touch()"
                  @input="$v.model.title.$touch()"
                />
              </v-col>
              <v-col v-if="currentTypeParams.is_active && !currentTypeParams.is_active.hide" cols="12" sm="6" md="4">
                <v-switch v-model="model.is_active" label="Включено" />
              </v-col>
              <v-col v-if="currentTypeParams.users && !currentTypeParams.users.hide" cols="12" sm="6" md="4">
                <PagingAutocomplete
                  :disabled="!currentTypeParams.users.editable"
                  :error-messages="inputErrors('users')"
                  :options="clients.map(({ user_id, first_name, last_name }) => ({ id: user_id, first_name, last_name }))"
                  :options-meta="clientsMeta"
                  :options-params="usersOptions"
                  :value="model.users"
                  clearable
                  item-value="id"
                  label="Клієнти"
                  multiple
                  no-filter
                  @blur="$v.model.users.$touch()"
                  @change="setUsers"
                  @input="$v.model.users.$touch()"
                >
                  <template v-slot:item="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
                  <template v-slot:selection="{ item }">{{ item.first_name }} {{ item.last_name }}</template>
                </PagingAutocomplete>
              </v-col>
              <v-col v-if="currentTypeParams.service_points && !currentTypeParams.service_points.hide" cols="12" sm="6" md="4">
                <PagingSelect
                  :disable="!currentTypeParams.service_points.editable"
                  :options="servicePoints.map(({ id, name }) => ({ id, name }))"
                  :options-meta="servicePointsMeta"
                  :options-params="servicePointsOptions"
                  :value="model.service_points"
                  chips
                  clearable
                  deletable-chips
                  item-text="name"
                  item-value="id"
                  label="Торгові точки"
                  multiple
                  return-object
                  @change="setServicePoints"
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-action>
                        <v-icon>mdi-information</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Залиште вибір порожнім для вказання всіх торгових точок </v-list-item-title>
                        <v-divider class="mt-2"></v-divider>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </PagingSelect>
              </v-col>
              <v-col v-if="currentTypeParams.devices && !currentTypeParams.devices.hide" cols="12" sm="6" md="4">
                <PagingSelect
                  :value="model.devices"
                  :disabled="!currentTypeParams.devices.editable"
                  :options="devices"
                  :options-meta="devicesMeta"
                  :options-params="devicesOptions"
                  chips
                  clearable
                  deletable-chips
                  item-value="id"
                  label="Автомати"
                  return-object
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-action>
                        <v-icon>mdi-information</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Залиште вибір порожнім для вказання всіх автоматів </v-list-item-title>
                        <v-divider class="mt-2"></v-divider>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template v-slot:item="{ item }">{{ getDeviceTitle(item.type) }} {{ item.id }}</template>
                  <template v-slot:selection="{ item }">{{ getDeviceTitle(item.type) }} {{ item.id }}</template>
                </PagingSelect>
              </v-col>
              <v-col v-if="currentTypeParams.device_types && !currentTypeParams.device_types.hide" cols="12" sm="6" md="4">
                <v-select
                  v-model="model.device_types"
                  :disabled="!currentTypeParams.device_types.editable"
                  :items="deviceTypes.map(({ id, name }) => ({ id, name }))"
                  chips
                  clearable
                  deletable-chips
                  item-text="name"
                  item-value="id"
                  label="Типи автоматів"
                  return-object
                  multiple
                >
                  <template v-slot:prepend-item>
                    <v-list-item>
                      <v-list-item-action>
                        <v-icon>mdi-information</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title> Залиште вибір порожнім для вказання всіх типів автоматів </v-list-item-title>
                        <v-divider class="mt-2"></v-divider>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </v-col>
              <v-col v-if="currentTypeParams.conditions && !currentTypeParams.conditions.hide" cols="12" sm="12">
                <ConditionsArray
                  :values="model.conditions"
                  :is-error="rangesError"
                  @add="(condition) => this.model.conditions.push(condition)"
                  @edit="(condition) => editCondition(condition)"
                  @delete="(index) => deleteCondition(index)"
                />
              </v-col>
              <v-col v-if="currentTypeParams.value_type && !currentTypeParams.value_type.hide" cols="12" sm="6" md="4">
                <v-select
                  v-model="model.value_type"
                  :disabled="!currentTypeParams.value_type.editable"
                  :items="valueTypes"
                  item-text="value"
                  item-value="id"
                  label="Вид бонуса"
                />
              </v-col>
              <v-col v-if="currentTypeParams.amount && !currentTypeParams.amount.hide" cols="12" sm="6" md="4">
                <v-text-field
                  v-model="model.amount"
                  :disabled="!currentTypeParams.amount.editable"
                  :error-messages="inputErrors('amount')"
                  :suffix="getValueType(model.value_type)"
                  type="number"
                  @blur="$v.model.amount.$touch()"
                  @input="$v.model.amount.$touch()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="currentTypeParams.description && !currentTypeParams.description.hide" cols="12" sm="12">
                <v-textarea
                  v-model="model.description"
                  :disabled="!currentTypeParams.description.editable"
                  :error-messages="inputErrors('description')"
                  label="Опис"
                  outlined
                  @blur="$v.model.description.$touch()"
                  @input="$v.model.description.$touch()"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="isEditing" color="primary" text @click="stepBack">
              <v-icon>mdi-chevron-left</v-icon>
              Назад
            </v-btn>
            <v-btn color="primary" text @click="yesClicked">Так</v-btn>
            <v-btn text @click="close">Нi</v-btn>
          </v-card-actions>
        </v-stepper-content>
      </v-stepper>
      <AskDialog
        :is-opened="dialogAsk"
        message="Останній діапазон повинен бути необмеженим"
        sub-message="Видалити параметр 'до' в останньому діапазоні?"
        no-button-text="Продовжити редагування"
        @yes="askYes"
        @no="() => (dialogAsk = false)"
      />
    </v-card>
  </v-dialog>
</template>

<script>
import DatePicker from '@/components/common/DatePicker'
import PagingAutocomplete from '@/components/common/PagingAutocomplete'
import PagingSelect from '@/components/common/PagingSelect'
import { mapActions, mapGetters, mapState } from 'vuex'
import manageFiltersOptions from '@/mixins/manageFiltersOptions'
import { validationMixin } from 'vuelidate'
import { required, minValue, minLength } from 'vuelidate/lib/validators'
import CloneDeep from 'lodash/cloneDeep'
import { PROMOTION_SCHEMA } from '@/const/apiSchemas'
import isEqual from 'lodash/isEqual'
import ConditionsArray from '@/components/marketing/promotions/ConditionsArray'
import AskDialog from '@/components/dialogs/AskDialog'
import { PROMOTION_PARAMS } from '@/const/promotionParams'
import moment from 'moment'
import convertDevicesTypes from '@/mixins/convertDevicesTypes'

export default {
  name: 'PromotionDialog',

  components: { AskDialog, ConditionsArray, PagingSelect, PagingAutocomplete, DatePicker },

  mixins: [validationMixin, manageFiltersOptions, convertDevicesTypes],

  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },

    isEditing: {
      type: Boolean,
      default: false,
    },

    editedItem: {
      type: Object,
      default: null,
    },
  },

  emits: ['yes', 'no'],

  data() {
    return {
      active: false,
      dialogAsk: false,
      currentStep: 1,
      usersOptions: null,
      servicePointsOptions: null,
      devicesOptions: null,
      model: null,
      currentTypeParams: {},
    }
  },

  validations() {
    return {
      model: {
        start_at: { required: this.currentTypeParams.start_at?.required ? required : false },
        expired_at: { required: this.currentTypeParams.expired_at?.required ? required : false },
        title: { required: this.currentTypeParams.title?.required ? required : false },
        description: {
          required: this.currentTypeParams.description?.required ? required : false,
          minLength: this.currentTypeParams.description?.minLength
            ? minLength(this.currentTypeParams.description.minLength)
            : false,
        },
        users: { required: this.currentTypeParams.users?.required ? required : false },
        amount: {
          required: this.currentTypeParams.amount?.required ? required : false,
          minValue: this.currentTypeParams.amount?.minValue ? minValue(this.currentTypeParams.amount.minValue) : false,
        },
      },
    }
  },

  watch: {
    active(val) {
      val || this.close()
    },

    editedItem(val, old) {
      if (isEqual(val, old)) return
      this.model = CloneDeep(val)
      if (this.isEditing) {
        const params = PROMOTION_PARAMS.find((item) => item.type === this.editedItem.type)
        if (!params) return
        this.currentTypeParams = params
        this.selectedServicePoints = [...this.editedItem.service_points.map((item) => item.id)]
      }
    },

    isOpened(val) {
      this.active = val
    },

    isEditing(val) {
      if (val) {
        this.currentStep = 2
      } else {
        this.currentStep = 1
        this.model = CloneDeep(PROMOTION_SCHEMA)
      }
    },
  },

  created() {
    this.initialize()
  },

  computed: {
    ...mapState('promotions', ['promotionTypes', 'valueTypes']),
    ...mapState('dictionaries', ['companyPromotionsDictionary', 'machinesTypesDictionary']),
    ...mapState('users', ['clients', 'clientsMeta']),
    ...mapState('servicePoints', ['servicePoints', 'servicePointsMeta']),
    ...mapState('devices', ['devices', 'devicesMeta']),
    ...mapState('companies', ['filter', 'isFilterLoaded']),

    ...mapGetters('companies', ['getBalanceHolderFilter', 'getServicePointFilter', 'getTerminalFilter', 'getDeviceFilter']),

    currentCompanyId() {
      return this.$route.params.id
    },

    rangesError() {
      return this.$v.$dirty && this.model.conditions.length === 0
    },
  },

  methods: {
    ...mapActions('users', ['loadClients']),
    ...mapActions('servicePoints', ['loadServicePoints']),
    ...mapActions('devices', ['loadDevices']),
    ...mapActions('companies', ['loadFilter']),

    async initialize() {
      this.model = this.isEditing ? CloneDeep(this.editedItem) : CloneDeep(PROMOTION_SCHEMA)
      this.active = this.isOpened
      await this.loadFilter(this.currentCompanyId)
      const defaultPayload = {
        company: this.currentCompanyId,
        forSearch: 1,
      }
      this.usersOptions = {
        loadingFunction: this.loadClients,
        payload: { ...defaultPayload },
      }
      this.servicePointsOptions = {
        loadingFunction: this.loadServicePoints,
        payload: { ...defaultPayload },
      }
      this.devicesOptions = {
        loadingFunction: this.loadDevices,
        payload: { ...defaultPayload },
      }
    },

    getValueType(type) {
      const val = this.valueTypes.find((item) => item.id === type)
      return val?.value || null
    },

    stepForward() {
      const params = PROMOTION_PARAMS.find((item) => item.type === this.model.type)
      if (!params) return
      this.currentTypeParams = params
      for (const [key, value] of Object.entries(this.currentTypeParams)) {
        if (value.presetValue !== undefined) {
          this.model[key] = value.presetValue
        }
      }
      this.currentStep++
    },

    stepBack() {
      this.currentStep--
    },

    inputErrors(fieldName) {
      const errors = []
      if (!this.$v.model[fieldName].$dirty) return errors
      !this.$v.model[fieldName].required && errors.push('Це поле обов"язкове')
      this.$v.model[fieldName].minLength === false &&
        errors.push(`Мінімальна довжина - ${this.$v.model[fieldName].$params.minLength.min} символів`)
      this.$v.model[fieldName].minValue === false &&
        errors.push(`Введіть значення від ${this.$v.model[fieldName].$params.minValue.min}`)
      return errors
    },

    setUsers(users) {
      this.model.users = users
    },

    setServicePoints(points) {
      this.model.service_points = points
      this.setSelectedServicePoints(points.map((item) => item.id))
      this.setSelectedTerminals([...this.terminals.map((item) => item.value)])
    },

    editCondition(condition) {
      const { payload, index } = condition
      this.model.conditions = this.model.conditions.map((item, i) => (i === index ? payload : item))
    },

    deleteCondition(index) {
      this.model.conditions = this.model.conditions.filter((item, i) => i !== index)
    },

    close() {
      this.$v.model.$reset()
      this.$emit('no')
      this.active = false
      this.currentStep = 1
      this.model = CloneDeep(PROMOTION_SCHEMA)
    },

    saveData() {
      const payload = CloneDeep({
        ...this.model,
        amount: this.model.amount !== null ? +this.model.amount : null,
        devices: this.model.devices.map((device) => ({ id: device.id })),
      })
      delete payload.id
      this.$emit('yes', payload)
      this.active = false
    },

    yesClicked() {
      this.$v.model.$touch()
      if (this.$v.$anyError || this.isError) return
      if (
        this.model.conditions &&
        this.model.conditions.length &&
        this.model.conditions[this.model.conditions.length - 1].condition_amount_to !== null
      ) {
        this.dialogAsk = true
      } else {
        if (this.model.start_at) this.model.start_at = moment(this.model.start_at).startOf('day').format('YYYY-MM-DD HH:mm:ss')
        if (this.model.expired_at)
          this.model.expired_at = moment(this.model.expired_at).endOf('day').format('YYYY-MM-DD HH:mm:ss')
        this.saveData()
      }
    },

    askYes() {
      this.model.conditions[this.model.conditions.length - 1].condition_amount_to = null
      this.dialogAsk = false
      this.saveData()
    },
  },
}
</script>

<style lang="scss" scoped>
.v-input.theme--light.v-text-field.v-text-field--is-booted.v-select.v-select--chips.v-select--is-multi {
  padding-top: 2px !important;
}
</style>
