<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="dateValue"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateValue"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="{ ...$attrs, ...attrs }"
        v-on="{ ...$listeners, ...on }"
      />
    </template>
    <v-date-picker v-model="dateValue" no-title scrollable>
      <v-btn text color="primary" @click="menu = false"> Закрити </v-btn>
      <v-btn text color="primary" @click="saveDate"> Так </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'DatePicker',

  inheritAttrs: false,

  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },

  events: ['change'],

  data: function () {
    return {
      dateValue: null,
      menu: false,
    }
  },

  watch: {
    value(val) {
      this.dateValue = val
    },
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.dateValue = this.value
    },

    saveDate() {
      this.$refs.menu.save(this.dateValue)
      this.$emit('change', this.dateValue)
    },
  },
}
</script>
